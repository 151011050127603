<template>
    <el-drawer
        :title="!formDatas.id ? '新增票品' : '编辑票品'"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        append-to-body
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <el-form v-if="!itemData && form1.step == 1" :model="form1" ref="form1" label-position="top" size="medium">
                <el-form-item prop="modle" label="请选择票品的类型:">
                    <el-radio-group v-model="form1.modle">
                        <div><el-radio :label="1">普通门票-单人票，只能一个人入场</el-radio></div>
                        <div class="MT20"><el-radio :label="2">普通门票-多人票，适用于多人票，比如情侣票，一张票允许两个人入场，用户票夹会得到两个入场码</el-radio></div>
                        <div class="MT20"><el-radio :label="3">乘车票-单人票</el-radio></div>
                        <div class="MT20"><el-radio :label="4">乘车票-多人票</el-radio></div>
                    </el-radio-group>
                </el-form-item>
            </el-form>

            <el-form
                v-if="itemData || form1.step == 2"
                :model="formDatas"
                :rules="rules"
                ref="formDatas"
                label-position="top"
            >
                <el-row :gutter="20">
                    <el-col :xs="24" :md="12">
                        <el-form-item prop="groupId" label="票品类型:">
                            <el-select v-model="formDatas.groupId" size="medium">
                                <el-option :value="1" label="普通门票"></el-option>
                                <el-option :value="2" label="车票"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :md="12">
                        <el-form-item prop="ticketName" label="票品名称:">
                            <!-- <el-input
                                v-model="formDatas.ticketName"
                                placeholder="请填写票品名称"
                                :maxlength="20"
                                size="medium"
                            ></el-input> -->
                            <el-autocomplete
                                v-model="formDatas.ticketName"
                                :fetch-suggestions="querySearch"
                                placeholder="请填写票品名称"
                                :maxlength="20"
                                size="medium"
                                style="width:100%"
                                @select="selectTicketName"
                                ></el-autocomplete>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="formDatas.memberNum > 1 || form1.modle == 2 || form1.modle == 4" :xs="24" :md="12">
                        <el-form-item prop="memberNum" class="has-tips">
                            <template slot="label">
                                <span>可入场人数: </span>
                                <div class="tips">
                                    <el-tooltip effect="dark" placement="top-start">
                                        <div slot="content">适用于多人票，比如双人票，一张票允许两个人入场，用户票夹会得到两个入场码</div>
                                        <span>适用于多人票，比如双人票，一张票允许两个人入场，用户票夹会得到两个入场码<div class="color-danger">如果演出是预售中或已产生过订单，不可修改</div></span>
                                    </el-tooltip>
                                </div>
                            </template>
                            <el-select v-model="formDatas.memberNum" size="medium" :disabled="formDatas.id && !formDatas.canUpdateMemberNum" placeholder="请选择">
                                <!-- <el-option :value="1" label="单人票"></el-option> -->
                                <el-option :value="2" label="双人"></el-option>
                                <el-option :value="3" label="三人"></el-option>
                                <el-option :value="4" label="四人"></el-option>
                                <el-option :value="5" label="五人"></el-option>
                                <el-option :value="6" label="六人"></el-option>
                                <el-option :value="7" label="七人"></el-option>
                                <el-option :value="8" label="八人"></el-option>
                                <el-option :value="9" label="九人"></el-option>
                                <el-option :value="10" label="十人"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="editType == 1" :xs="24" :md="12">
                        <el-form-item prop="status" label="销售状态:">
                            <el-radio-group v-model="formDatas.status" size="medium">
                                <el-radio :label="0" border>正常销售</el-radio>
                                <el-radio :label="1" border>暂停销售</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
               
                    <el-col :xs="24" :md="12">
                        <el-form-item prop="startTime" label="销售开始时间:">
                            <el-date-picker v-model="formDatas.startTime" type="datetime" placeholder="销售开始时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm" size="medium"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :md="12">
                        <el-form-item prop="endTime" label="销售结束时间:">
                            <el-date-picker v-model="formDatas.endTime" type="datetime" placeholder="销售结束时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm" size="medium"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :xs="24" :md="12">
                        <el-form-item prop="datetimerange2" label="验票有效期:">
                            <el-date-picker
                                v-model="formDatas.datetimerange2"
                                type="datetimerange"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                                size="medium"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col> -->
                
                    <el-col :xs="24" :md="12">
                        <el-form-item prop="sellPrice" label="票品售价:">
                            <el-input-number style="width:100%" v-model="formDatas.sellPrice" :precision="2" controls-position="right" :min="0.00" :max="99999999.99" size="medium" placeholder="请填写票品售价"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :md="12">
                        <el-form-item prop="originalPrice" label="票品原价:">
                            <el-input-number style="width:100%" v-model="formDatas.originalPrice" :precision="2" controls-position="right" :min="0.00" :max="99999999.99" size="medium" placeholder="请填写票品原价"></el-input-number>
                        </el-form-item>
                    </el-col>
                
                    <el-col :xs="24" :md="12">  
                        <el-form-item prop="accountLimitNum" >
                            <span slot="label">票品限购: <small>对当前票品限制最多允许购买的票数,0为不限购</small></span>
                            <el-input-number style="width:100%" v-model="formDatas.accountLimitNum" :precision="0" controls-position="right" :min="0" :max="99999999" size="medium" placeholder="请填写票品限购"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col v-if=" (!formDatas.id || editType != 1 ) && ( !hallId  || formDatas.groupId == 2 ) " :xs="24" :md="12"> 
                        <el-form-item prop="totalNum" label="库存:">
                            <el-input-number style="width:100%" v-model="formDatas.totalNum" :precision="0" controls-position="right" :min="1" :max="99999999" size="medium" placeholder="请填写库存"></el-input-number>
                        </el-form-item>    
                    </el-col>
                </el-row>
                <ticket-type :formData.sync="formDatas"></ticket-type>
                <real-name :formData.sync="formDatas"></real-name>
                <el-form-item prop="instruction" label="票品说明:">
                    <el-input
                        v-model="formDatas.instruction"
                        placeholder="请填写票品说明"
                        type="textarea"
                        size="medium"
                        maxlength="100"
                        :autosize="{minRows:2}"
                        show-word-limit
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>

        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button v-if="!itemData && form1.step == 1" type="primary" @click="form1.step = 2">下一步</el-button>
            <el-button v-if="itemData || form1.step == 2" type="primary" @click="submitForm()" :loading="btnLoading"
                >确 认</el-button
            >
        </div>

    </el-drawer>
</template>

<script>
import TicketType from './ticket-type';
import RealName from './real-name';
const defaultFormData = {
    ticketName: "",
    // datetimerange1: [],
    startTime: "",
    endTime: "",
    // datetimerange2:[],
    validStartTime:'',
    validEndTime:'',
    sellPrice:undefined,
    originalPrice:undefined,
    accountLimitNum:0,
    totalNum:0,
    instruction:'',
    status:0,
    groupId:1,
    memberNum: 1,
    
    ticketType: 1,
    pickupTimes:[],
    pickupAddress:'',
    pickupLongitude: '',
    pickupLatitude:'',
    pickupInstruction:'上门自取时,请提供二维码,手机号,身份证原件(如代领还需代领人身份证原件)',
    mailInstruction:'免邮/采用到付收费',

    realName: 0,
    buyType: 3,
};
export default {
    props: {
        itemData: {
            type: Object,
            default: defaultFormData,
        },
        index: {
            type: Number,
            default: 0,
        },
        ticketPrices: {
            type: Array,
            default: () => []
        },
        activityId:{
            type:[Number,String],
            default:''
        },
		startTime:{
			type: String,
			default: ''
		},
        endTime:{
			type: String,
			default: ''
		},
		hallId:{
			type:[Number,String],
			default: ''
		},
        //编辑类型：1、演出详情（编辑）2、演出发布
        editType: {
            type: [Number,String],
            default: 1
        }
    },
    components: {TicketType,RealName},
    data() {
        return {
            drawerShow: false,
            btnLoading: false,
            formDatas: {
                ...defaultFormData,
            },
			// datetimerangeEnd:'',
            rules: {
                ticketName: [{ required: true, message: "请填写票品名称" }],
                startTime:[{ required: true, message: "请填写销售开始时间" },{validator: this.checkStartTime}],
                endTime:[{ required: true, message: "请填写销售结束时间" },{validator: this.checkEndTime}],
                sellPrice:[{required: true, message: "请填写票品售价",trigger:'blur'}],
                originalPrice:[{required: true, message: "请填写票品原价",trigger:'blur'}],
                accountLimitNum:[{required: true, message: "请填写限购数量"}],
                totalNum:[{required: true, message: "请填写票品库存",trigger:'blur'}],
                memberNum: [{ required: true, message: "请选择可入场人数" }],
            },
            form1: {
                modle: 1,
                step: 1,
            }
        };
    },
    watch: {
        drawerShow(v) {
            if (v) {
                this.formDatas = this.itemData
                    ? { 
                        ...defaultFormData,
                        ...this.itemData, 
                        sort: this.index, 
                        // datetimerange1: this.itemData.startTime ? [this.itemData.startTime,this.itemData.endTime] : [],
                        // datetimerange2: this.itemData.validStartTime ? [this.itemData.validStartTime || '',this.itemData.validEndTime || ''] : [],
                    }
                    : { 
                        ...defaultFormData,
						// datetimerange2: this.datetimerange && this.datetimerange[0] ? [this.datetimerange[0],this.datetimerangeEnd] : '',
                        // endTime: this.startTime && new Date(this.startTime.split(' ')[0] + ' ' + (this.endTime && this.endTime.split(' ')[1]) ) || '',
                        sort: this.index,
                        pickupTimes: []
                    };
            }
        },
        "form1.modle"(v){
            if( v == 1 || v == 2 ){
                this.$set(this.formDatas,'groupId',1)
            }
            if( v == 3 || v == 4 ){
                this.$set(this.formDatas,'groupId',2)
            }
            if(v == 2 || v == 4){
                this.$set(this.formDatas,'memberNum',"")
            }
        },
        "formDatas.ticketType"(v){
            this.$nextTick(() => {
                this.$refs.formDatas && this.$refs.formDatas.clearValidate(["pickupTimes","pickupAddress","pickupLongitude","pickupInstruction","mailInstruction"])
            })
        }
    },
    computed:{
        restaurants(){
            return [
                {value:'早鸟票'},
                {value:'预售票'},
                {value:'全价票'},
            ]
        }
		
    },
    methods: {
        close() {
            this.formDatas = { 
                ...defaultFormData,
                pickupTimes: []
            };
            this.$refs.formDatas && this.$refs.formDatas.resetFields();
            this.form1 = {
                step:1,
                modle:1
            };
            this.drawerShow = false;
        },
        show() {
			// const timeAt = this.datetimerange && this.datetimerange[0] || '';
            // const newDate = timeAt && timeAt.split(' ')[0] + " 23:59:59" || '';
            // this.datetimerangeEnd = newDate;
            this.drawerShow = true;
			console.log(this.hallId)
        },
        querySearch(queryString, cb) {
            var restaurants = this.restaurants;
            // 调用 callback 返回建议列表的数据
            cb(restaurants);
        },
        selectTicketName(e){
            if( !this.formDatas.endTime ){
                this.formDatas.endTime = this.startTime && this.startTime.split(' ')[0] + ' 00:00:00' || '';
                if( e.value == '全价票' ){
                    this.formDatas.endTime = this.startTime && this.startTime.split(' ')[0] + ' 22:00:00' || '';
                }
            }
        },
        checkStartTime(rule, value, callback){
            if ( this.$utils.compareDate(value,this.formDatas.endTime) ) {
                callback(new Error("销售开始时间不能大于销售结束时间"));
            } else {
                callback();
            }
        },
        checkEndTime(rule, value, callback){
            if ( this.$utils.compareDate(this.formDatas.startTime,value) ) {
                callback(new Error("销售结束时间不能小于销售结束时间"));
            } else {
                callback();
            }
        },
        //表单提交
        submitForm() {
            this.$refs["formDatas"].validate((valid) => {
                //this.formDatas.index = this.index;
                if (valid) {
                    const sameName = this.ticketPrices.find( v => v.ticketName == this.formDatas.ticketName);
                    if( sameName && (!this.itemData || this.itemData.ticketName != this.formDatas.ticketName )){
                        return this.$message.error('同场次下该票品名称已被占用');
                    }    

                    // this.formDatas.startTime = this.formDatas.datetimerange1[0];
                    // this.formDatas.endTime = this.formDatas.datetimerange1[1];
                    // this.formDatas.validStartTime = this.formDatas.datetimerange2[0];
                    // this.formDatas.validEndTime = this.formDatas.datetimerange2[1];
                    this.formDatas.activityId = this.activityId;

                    if( this.formDatas.ticketType != 2 && this.formDatas.ticketType != 4 ){
                        this.formDatas.mailInstruction = "";
                    }
                    if( this.formDatas.ticketType != 3 && this.formDatas.ticketType != 4 ){
                        this.formDatas.pickupInstruction = "";
                    
                        this.formDatas.pickupTimes = [];
                        this.formDatas.pickupAddress = "";
                        this.formDatas.pickupLongitude = "";
                        this.formDatas.pickupLatitude = "";
                    }
					
					console.log(this.formDatas)
					//return
                    this.formDatas = { ...this.formDatas };
                    this.$emit("onUpdate", this.formDatas,() => {
                        this.close();
                    });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>