<template>
    <div v-if="formData">
        
        <el-form-item prop="ticketType" label="票品性质：">
            <el-radio-group v-model="formData.ticketType" size="medium">
                <el-radio :label="1" border>电子票</el-radio>
                <el-radio :label="2" border>实体票寄送</el-radio>
                <el-radio :label="3" border>实体票自取</el-radio>
                <el-radio :label="4" border>实体票自取/寄送</el-radio>
            </el-radio-group>
        </el-form-item>

        <el-row v-if="formData.ticketType == 3 || formData.ticketType == 4" :gutter="20">
            <el-col>
                <el-card shadow="never">
                    <div slot="header"><i class="el-icon-s-ticket"></i> 实体票自取</div>
                    <el-form-item class="MT10" prop="pickupTimes" :rules="[{required: true, message: `请选择自取时间段`}]" :show-message="formData.pickupTimes && formData.pickupTimes.length < 1">
                        <span slot="label">
                            自取时间: 
                            <el-button v-if="!formData.pickupTimes || formData.pickupTimes.length < 1" type="text" icon="el-icon-timer"  @click="drawerShow=true">新增时间段</el-button>
                        </span>
                        <div class="pickupTimes clearfix">
                            <div v-for="(item,index) in formData.pickupTimes" :key="index" class="fl">
                                <i class="el-icon-time"></i> 
                                {{item.startTime}} 到 {{item.endTime}} 
                                <el-button type="text"  size="small" icon="el-icon-close" @click="removeTimes(index)">删除</el-button>
                            </div>
                        </div>
                    </el-form-item>
                    <el-row :gutter="20">
                        <el-col :xs="24" :md="12" >
                            <el-form-item prop="pickupAddress" :rules="[{required: true, message: `请填写自取地址`}]">
                                <span slot="label">
                                    自取地址: 
                                    <el-button type="text" icon="el-icon-map-location" @click="$refs.selectAddress.show()">选择地址</el-button> 
                                </span>
                                <el-input type="textarea" v-model="formData.pickupAddress" size="medium"></el-input>
                                <small v-if="formData.pickupLongitude">经度: {{formData.pickupLongitude}} 纬度: {{formData.pickupLatitude}}</small>
                            </el-form-item>
                            <el-form-item prop="pickupLongitude" :rules="[{required: formData.pickupAddress ? true : false, message: `请使用地图选择正确的自取地址经纬度`}]" style="margin:0">
                                <el-input v-model="formData.pickupLongitude" type="hidden" style="display:none"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :md="12">
                            <el-form-item prop="pickupInstruction" label="说明信息:" :rules="[{required: true, message: `请输入实体票自取说明`}]">
                                <el-input type="textarea" v-model="formData.pickupInstruction" size="medium" maxlength="100"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>

                
        <el-row v-if="formData.ticketType == 2 || formData.ticketType == 4" :gutter="20">
            <el-col>
                <el-card shadow="never">
                    <div slot="header"><i class="el-icon-s-ticket"></i> 实体票寄送</div>
                    <el-form-item prop="mailInstruction" label="说明信息:" :rules="[{required: true, message: `请输入实体票寄送说明`}]" class="MT10">
                        <el-input type="textarea" v-model="formData.mailInstruction" size="medium" maxlength="100"></el-input>
                    </el-form-item>
                </el-card>
            </el-col>
        </el-row>

        <el-drawer
            title="新增自取时间段"
            :visible.sync="drawerShow"
            :wrapperClosable="false"
            :destroy-on-close="true"
            size="auto"
            @close="close"
            append-to-body
        >
            <div class="drawer-content">
                <el-form
                    :model="timeForm"
                    ref="timeForm"
                    label-position="top"
                >
                    <el-form-item prop="datetimerange" label="自取时间段:" :rules="[{required: true, message: '请选择时间段'}]">
                        <el-date-picker
                            v-model="timeForm.datetimerange"
                            type="datetimerange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            size="medium"
                            unlink-panels>
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <div class="drawer-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="submitForm()"
                    >提交</el-button
                >
            </div>
        </el-drawer>

        <select-address 
            ref="selectAddress" 
            :address.sync="formData.pickupAddress" 
            :lng.sync="formData.pickupLongitude" 
            :lat.sync="formData.pickupLatitude">
        </select-address>
    </div>
</template>

<script>
import SelectAddress from '@/components/SelectAddressBaiduMap';
export default {
    props:{
        formData: {
            type: Object,
            default: null
        },
    },
    components:{SelectAddress},
    data(){
        return{
            drawerShow:false,
            timeForm:{
                datetimerange:[]
            }
        }
    },
    methods:{
        //关闭弹层
        close() {
            this.timeForm = {datetimerange:[]};
            this.$refs.timeForm.resetFields();
            this.drawerShow = false;
        },
        //添加时间段表单提交
        submitForm() {
            let self = this;
            self.$refs["timeForm"].validate((valid) => {
                if (valid) {
                    this.formData.pickupTimes.push({
                        startTime: this.timeForm.datetimerange[0],
                        endTime: this.timeForm.datetimerange[1]
                    })
                    this.close();

                } else {
                    return false;
                }
            });
        },
        // 删除自取时间段
        removeTimes(index){
            this.$confirm('确定要删除当前时间段吗？', '提示', {
                type: 'warning',
                center: true,
                closeOnClickModal: false
            }).then(() => {
                this.formData.pickupTimes = this.formData.pickupTimes.filter( (v,i) => i !== index);
            })
        }
    }

}
</script>

<style lang="scss" scoped>
.pickupTimes{
    font-size: 12px;
    .fl{
        margin: 0 10px;
        line-height: normal;
    }
}
.el-card{
    margin-bottom: 10px;
}
</style>