<template>
    <!-- 新增场次 -->
    <el-dialog
        title="新增场次"
        :visible.sync="dialogVisible"
        width="500px"
        center>
        <div class="dialog-content">
            <el-form :model="copyInfo" ref="copyInfo">
                <!-- <el-form-item prop="name" label="场次名称:" :rules="[{required: true, message: '请输入场次名称'}]">
                    <el-input v-model.trim="copyInfo.name" placeholder="请输入场次名称" size="medium" maxlength="40"></el-input>
                </el-form-item> -->
                <el-form-item prop="startTime" :rules="[{required:  true, message: '请选择演出开始时间'}]">
                    <span slot="label">演出开始时间: </span>
                    <el-date-picker v-model="copyInfo.startTime" type="datetime" placeholder="演出开始时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm" default-time="20:30:00" size="medium"></el-date-picker>
                </el-form-item>
               <el-form-item prop="endTime" :rules="[{required:  true, message: '请选择演出结束时间'}]">
                    <span slot="label">演出结束时间: </span>
                    <el-date-picker v-model="copyInfo.endTime" type="datetime" placeholder="演出结束时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm" default-time="22:00:00" size="medium"></el-date-picker>
                </el-form-item>

                <el-form-item prop="index" label="快捷复制:" required>
                    <el-select v-model="copyInfo.index">
                        <el-option v-for="(item,index) in sessionsSelectList" :key="index" :value="item.sort" :label="`从场次${item.sort + 1}复制`"></el-option>
                        <el-option :value="-1" label="不从其他场次复制信息"></el-option>
                    </el-select>
                    <small v-if="copyInfo.index != -1"><span class="color-danger">从其他场次复制后，请注意修改需要区别的信息，比如开票时间等</span></small>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addOs">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
const defaultData = {
    sessionName:'', 
    startTime:'',
    endTime:'',
    datetimerange:[],
    artistNames:'',
    ticketPrices:[],
    status:1,
    artistList: []
}
export default {
    props:{
        formData:{
            type: Object,
            default: null
        },
        activityId:{
            type:[Number,String],
            default:''
        }
    },
    data(){
        return {
            dialogVisible:false,
            copyInfo:{
                index:-1,
                // name:'',
                startTime: "",
                endTime: ""    
            }
        }
    },
    computed:{
        sessions:{
            get(){
                return this.formData.sessions || []
            },
            set(v){
                this.$emit('update:formData',{...this.formData,sessions:v})
            }
        },
        sessionsSelectList(){
            let _arry = this.formData.sessions && this.formData.sessions.filter(v => v.status != -1) || [];
            _arry.map((item,index) => {
                item.sort = index 
            })
            _arry.reverse();
            return _arry;
        }
    },
    methods:{
        show(){
            if( this.sessionsSelectList.length >= 30 ){
                this.$alert('当前演出场次过多，最多只允许存在30个场次，如有更多场次需求，请创建新的演出', '提示', {
                    confirmButtonText: '知道了',
                });
                return;
            }
            this.copyInfo.index = this.sessionsSelectList.length - 1;
            this.dialogVisible = true;
        },
        //添加场次
        addOs(){
            this.$refs["copyInfo"].validate((valid) => {
                if (valid) {

                    // const sameName = this.sessions.find( v => v.sessionName == this.copyInfo.name);
                    // if( sameName ){
                    //     return this.$message.error('场次名称已存在,请重新输入');
                    // }

                    if( this.$utils.compareDate( this.copyInfo.startTime, this.copyInfo.endTime ) ){
                        return this.$message.error("演出开始时间必须小于演出结束时间");
                    }

                    const _os = this.sessions[this.copyInfo.index] ? {...this.sessions[this.copyInfo.index]} : {...defaultData};
                    let _ticketPrices = this.copyInfo.index == -1 ? [] : JSON.parse(JSON.stringify(this.sessions[this.copyInfo.index].ticketPrices));
					let _performers = this.copyInfo.index == -1 ? [] : JSON.parse(JSON.stringify(this.sessions[this.copyInfo.index].performers));
                    
                    if( _ticketPrices.length > 0 ){
                        _ticketPrices = _ticketPrices.filter( v => v.status != -1);
                        _ticketPrices.map( (v,i) => {
                            if(v.id){
                                v.id = ""
                                v.canBuySessionIds = ""
                                v.canCheckSessionIds = ""
                                v.sellNum = 0
                                v.refundNum = 0
                            }
                            v.sort = i;
                        })
                    }
                    const _item = {
                        ..._os,
                        sessionName: "",
                        id:"",
                        activityId:this.activityId,
                        ticketPrices:[..._ticketPrices],
                        status: 1,
                        isDisplay: 1,
                        startTime: this.copyInfo.startTime,
                        endTime: this.copyInfo.endTime,
						performers: _performers
                    }
                    this.$set(this.sessions,this.sessions.length,{
                        ..._item
                    })

                    this.copyInfo = {startTime:'',endTime:'',index:-1};
                    this.$refs.copyInfo.resetFields();
                    this.dialogVisible = false;
					console.log(this.sessions)
                }
            })
        },
    }
}
</script>