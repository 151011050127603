<template>
    <el-drawer
        title="调整库存"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <el-alert type="warning" show-icon :closable="false" title="请注意：由于时效的原因，你当前看到的剩余票数可能已经产生变动"></el-alert>
            <el-form
                :model="formDatas"
                :rules="rules"
                ref="formDatas"
                label-position="top"
                class="MT10"
            >
				<el-form-item prop="ticketName" label="当前票品:" v-if="itemData">
					{{itemData.ticketName}} <span class="ML20">￥{{itemData.sellPrice}}</span>
				</el-form-item>
                <el-form-item prop="stockType">
                    <span slot="label">调整类型: <small>提交前请先选择是增加还是减少剩余库存</small></span>
                    <el-radio-group v-model="formDatas.stockType" size="medium" @change="changeStock">
                        <el-radio :label="1" border>增加</el-radio>
                        <el-radio :label="0" border>减少</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="stockNum" label="调整数量:">
                    <el-input-number style="width:100%" v-model="formDatas.stockNum" :precision="0" controls-position="right" :min="1" :max="99999999"  size="medium" @change="changeStock"></el-input-number>
                </el-form-item>
            </el-form>
            <el-table
                v-if="itemData"
                :data="[...itemData]"
                style="width: 100%"
                size="small"
                header-row-class-name="page-list-table"
            >
                <el-table-column label="总库存">
                    <template>{{ total }}</template>
                </el-table-column>
                <el-table-column label="已销售">
                    <template>{{itemData.sellNum}}</template>
                </el-table-column>
                <el-table-column label="待支付">
                    <template>{{itemData.occupyNum}}</template>
                </el-table-column>
                <el-table-column label="剩余">
                    <template><span :class="remaining < 0 ? 'color-danger' : ''">{{remaining}}</span></template>
                </el-table-column>
            </el-table>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="submitForm()" :loading="btnLoading"
                >确 认</el-button
            >
        </div>
    </el-drawer>
</template>

<script>
const defaultData = {
    stockType:1,
    stockNum: 0
}
export default {
    props:{
        itemData: {
            type: Object,
            default: null,
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    watch:{
        drawerShow(v){
            if(v){
                this.total = parseInt(this.itemData.totalNum);
                this.remaining = this.total - parseInt(this.itemData.sellNum || 0) - parseInt(this.itemData.occupyNum || 0)
            }
        }
    },
    data(){
        return{
            drawerShow: false,
            btnLoading: false,
            formDatas:{
                ...defaultData
            },
            rules:{
                stockNum:[{ required: true, message: "请填写增/减数量" }]
            },
            total:0,
            remaining:0
        }
    },
    methods:{
        close(){
            this.$refs.formDatas.resetFields();
            this.drawerShow = false;
            this.formDatas = {
                ...defaultData
            }
        },
        show(){
            this.drawerShow = true;
        },
        changeStock(){
            this.total = this.formDatas.stockType == 1 ? parseInt(this.itemData.totalNum) + parseFloat(this.formDatas.stockNum) : parseInt(this.itemData.totalNum) - parseFloat(this.formDatas.stockNum)
            this.remaining = this.total - parseInt(this.itemData.sellNum || 0) - parseInt(this.itemData.occupyNum || 0)
        },
        //表单提交
        submitForm() {
            this.$refs["formDatas"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true;    
                    this.$request({
                        url: '/activity/ticket/editStock',
                        params: {
                            stockNum: this.formDatas.stockType == 1 ? parseInt(this.formDatas.stockNum) : -parseInt(this.formDatas.stockNum),
                            ticketId: this.itemData.id,
                        },
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$emit("onUpdate", {...this.itemData,sort:this.index,totalNum:this.total});
                                this.close();
                            } else {
                                this.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            self.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });   
                } else {
                    return false;
                }
            });
        },
    }
}
</script>