<template>
    <div>
        <el-form-item prop="realName" label="实名认证：">
            <el-radio-group v-model="formData.realName" size="medium">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
        </el-form-item>

        <el-card shadow="never" v-if="formData.realName == 1">
            <el-form-item prop="buyType" label="证件绑定时机:" :rules="[{required: true, message: `请选择证件绑定时机`}]">
                <el-radio-group v-model="formData.buyType" size="medium">
                    <el-radio :label="2">用户绑定观演人后才能下单购票</el-radio>
                    <el-radio :label="3" :disabled="formData.ticketType == 2 || formData.ticketType == 4">用户可以先购票后再绑定观演人</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-card>

    </div>
</template>

<script>
export default {
    props:{
        formData: {
            type: Object,
            default: null
        }
    },
    watch:{
        'formData.ticketType'(v){
            if( v == 2 || v == 4 ){
                this.formData.buyType = 2
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.el-card{
    margin-bottom: 20px;
}
</style>